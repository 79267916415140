import React from 'react';
import PropTypes from 'prop-types';

import './TextInput.css';

class TextInput extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
  };

  static defaultProps = {
    value: '',
    id: `TextInput-${new Date().toTimeString()}`,
    label: undefined,
    type: 'text',
  };

  render() {
    console.log(this.props.label, 'this.props.label');
    return (
      <div
        className="input-dcmcloud-container"
        style={
          this.props.label === 'New label'
            ? { display: 'flex', flexDirection: 'column' }
            : {}
        }
      >
        {this.props.label && (
          <label
            className="input-dcmcloud-label"
            style={this.props.label === 'New label' ? { color: 'white' } : {}}
            htmlFor={this.props.id}
          >
            {this.props.label}
          </label>
        )}
        <input
          type={this.props.type}
          style={
            this.props.label === 'New label'
              ? { padding: '0px', margin: '0px' }
              : {}
          }
          id={this.props.id}
          className="form-control input-dcmcloud"
          {...this.props}
        />
      </div>
    );
  }
}

export { TextInput };
