import { asyncComponent, retryImport } from '@dcmcloud/ui';
import DCMCloud from '@dcmcloud/core';

const { urlUtil: UrlUtil } = DCMCloud.utils;

// Dynamic Import Routes (CodeSplitting)
const IHEInvokeImageDisplay = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "IHEInvokeImageDisplay" */ './IHEInvokeImageDisplay.js'
    )
  )
);

const SignIn = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "IHEInvokeImageDisplay" */ '../components/SignIn.jsx'
    )
  )
);
const Pacs = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "IHEInvokeImageDisplay" */ '../components/Pacs.js'
    )
  )
);
const test = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "IHEInvokeImageDisplay" */ '../components/test.js'
    )
  )
);
const MyComponent = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "IHEInvokeImageDisplay" */ '../components/MyComponent'
    )
  )
);
const ThreeDView = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "IHEInvokeImageDisplay" */ '../components/ThreeDView'
    )
  )
);

const PanaromicView = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "IHEInvokeImageDisplay" */ '../components/PanaromicView'
    )
  )
);

const SingleTeeth = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "ViewerRouting" */ './ViewerRouting.js')
  )
);
const Listviewer = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "IHEInvokeImageDisplay" */ '../connectedComponents/ListViewer.js'
    )
  )
);
// const Mylist = asyncComponent(() =>
//   retryImport(() =>
//     import(
//       /* webpackChunkName: "IHEInvokeImageDisplay" */ '../components/Mylist'
//     )
//   )
// );
const SideView = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "IHEInvokeImageDisplay" */ '../components/SideView'
    )
  )
);
const ViewerRouting = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "ViewerRouting" */ './ViewerRouting.js')
  )
);

const XradViewerRouting = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "ViewerRouting" */ './ViewerRouting.js')
  )
);
// const radXViewerRouting = asyncComponent(() =>
//   retryImport(() =>
//     import(/* webpackChunkName: "ViewerRouting" */ './ViewerRouting.js')
//   )
// );

const XradFMXViewerRouting = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "ViewerRouting" */ './ViewerRouting.js')
  )
);

const StudyListRouting = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "StudyListRouting" */ '../studylist/StudyListRouting.js'
    )
  )
);
const StandaloneRouting = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ConnectedStandaloneRouting" */ '../connectedComponents/ConnectedStandaloneRouting.js'
    )
  )
);
const ViewerLocalFileData = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ViewerLocalFileData" */ '../connectedComponents/ViewerLocalFileData.js'
    )
  )
);
const ProtectedViewL = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ViewerLocalFileData" */ '../connectedComponents/ProtectedViewL.js'
    )
  )
);
const FMX18 = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ViewerLocalFileData" */ '../../src/components/LayoutView/FMX18.jsx'
    )
  )
);

const KeyImages = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ViewerLocalFileData" */ '../../src/components/KeyImages/KeyImages.jsx'
    )
  )
);

const KeyImagesList = asyncComponent(() =>
  retryImport(() =>
    import(
      /* webpackChunkName: "ViewerLocalFileData" */ '../../src/components/KeyImages/KeyImagesList.jsx'
    )
  )
);

const reload = () => window.location.reload();
// const keyaVal =
//   localStorage.getItem('AwsBucketName') +
//   '^' +
//   localStorage.getItem('AwsAccessKeyId') +
//   '^' +
//   localStorage.getItem('AwsSecretAccessKey');
// const keya = new Buffer.from(keyaVal).toString('base64');
const ROUTES_DEF = {
  default: {
    fmx: {
      path: '/fmx18',
      component: FMX18,
    },
    keyImages: {
      path: '/keyimages/:patientName',
      component: KeyImages,
    },
    // keyImagesList: {
    //   path: '/keyimageslist',
    //   component: KeyImagesList,
    // },
    viewer: {
      path: '/viewer/:studyInstanceUIDs',
      component: ViewerRouting,
    },
    Xradviewer: {
      path: '/xrad/viewer/:studyInstanceUIDs',
      component: XradViewerRouting,
    },
    // radXviewer: {
    //   path: '/radx/viewer/:studyInstanceUIDs',
    //   component: radXViewerRouting,
    // },
    XradFMXviewer: {
      path: '/FMXviewer/:studyInstanceUIDs',
      component: XradFMXViewerRouting,
    },
    standaloneViewer: {
      path: '/viewer',
      component: StandaloneRouting,
    },
    SignIn: {
      path: ['/signin', '/', '/signin/:user1'],
      component: SignIn,
    },
    list: {
      path: ['/studylist'],
      component: StudyListRouting,
      condition: appConfig => {
        return appConfig.showStudyList;
      },
    },
    local: {
      path: ['/local'],
      // component: ViewerLocalFileData,
      component: ProtectedViewL,
    },

    Pacsviewer: {
      path: ['/list'],
      // component: ViewerLocalFileData,
      component: Listviewer,
    },
    ThreeDView: {
      path: ['/3d'],
      // component: ViewerLocalFileData,
      component: ThreeDView,
    },

    PanaromicView: {
      path: ['/PanaromicView'],
      // component: ViewerLocalFileData,
      component: PanaromicView,
    },
    SingleTeeth: {
      path: ['/singleTeeth/:studyInstanceUIDs'],
      // component: ViewerLocalFileData,
      component: SingleTeeth,
    },
    test: {
      path: ['/test'],
      // component: ViewerLocalFileData,
      component: test,
    },
    SideView: {
      path: ['/SideView'],
      // component: ViewerLocalFileData,
      component: SideView,
    },
    MyComponent: {
      path: ['/MyComponent'],
      // component: ViewerLocalFileData,
      component: MyComponent,
    },
    IHEInvokeImageDisplay: {
      path: '/IHEInvokeImageDisplay',
      component: IHEInvokeImageDisplay,
    },
  },
  gcloud: {
    viewer: {
      path:
        '/projects/:project/locations/:location/datasets/:dataset/dicomStores/:dicomStore/study/:studyInstanceUIDs',
      component: ViewerRouting,
      condition: appConfig => {
        return !!appConfig.enableGoogleCloudAdapter;
      },
    },
    list: {
      path:
        '/projects/:project/locations/:location/datasets/:dataset/dicomStores/:dicomStore',
      component: StudyListRouting,
      condition: appConfig => {
        const showList = appConfig.showStudyList;

        return showList && !!appConfig.enableGoogleCloudAdapter;
      },
    },
  },
};

const getRoutes = appConfig => {
  const routes = [];
  for (let keyConfig in ROUTES_DEF) {
    const routesConfig = ROUTES_DEF[keyConfig];

    for (let routeKey in routesConfig) {
      const route = routesConfig[routeKey];
      const validRoute =
        typeof route.condition === 'function'
          ? route.condition(appConfig)
          : true;

      if (validRoute) {
        routes.push({
          path: route.path,
          Component: route.component,
        });
      }
    }
  }

  return routes;
};

const parsePath = (path, server, params) => {
  let _path = path;
  const _paramsCopy = Object.assign({}, server, params);

  for (let key in _paramsCopy) {
    _path = UrlUtil.paramString.replaceParam(_path, key, _paramsCopy[key]);
  }

  return _path;
};

const parseViewerPath = (appConfig = {}, server = {}, params) => {
  let viewerPath = ROUTES_DEF.default.viewer.path;
  if (appConfig.enableGoogleCloudAdapter) {
    viewerPath = ROUTES_DEF.gcloud.viewer.path;
  }

  return parsePath(viewerPath, server, params);
};

const parseStudyListPath = (appConfig = {}, server = {}, params) => {
  let studyListPath = ROUTES_DEF.default.list.path;
  if (appConfig.enableGoogleCloudAdapter) {
    studyListPath = ROUTES_DEF.gcloud.list.path || studyListPath;
  }

  return parsePath(studyListPath, server, params);
};

export { getRoutes, parseViewerPath, parseStudyListPath, reload };
