import './toolbar-button.styl';
import './toolbarButtonStyle.css';
import { Icon } from './../elements/Icon';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import classnames from 'classnames';
import { withTranslation } from '../contextProviders';

export function ToolbarButton(props) {
  const { isActive, icon, labelWhenActive, onClick, t } = props;
  const className = classnames(props.className, { active: isActive });
  const iconProps = typeof icon === 'string' ? { name: icon } : icon;
  const label = isActive && labelWhenActive ? labelWhenActive : props.label;
  //const id = isActive && labelWhenActive ? labelWhenActive : props.id;
  const arrowIconName = props.isExpanded ? 'caret-up' : 'caret-down';
  const arrowIcon = props.isExpandable && (
    <Icon name={arrowIconName} className="expand-caret" />
  );

  const handleClick = event => {
    if (props.label === 'Copy Link') {
      var inputc = document.body.appendChild(document.createElement('input'));
      inputc.value = window.location.href;
      inputc.focus();
      inputc.select();
      document.execCommand('copy');
      inputc.parentNode.removeChild(inputc);
      alert('Shareable link copied to the clipboard...');
    } else {
      onClick(event, props);
    }
  };

  const cypressSelectorId = props.label.toLowerCase();

  return (
    <>
      <div
        id={props.id}
        style={{ visibility: `${!props.show ? 'visible' : 'hidden'}` }}
        className={`${className} ${label}`}
        onClick={handleClick}
        data-cy={cypressSelectorId}
      >
        {iconProps && <Icon {...iconProps} />}
        <div className="toolbar-button-label">
          <span
            className={`tooltiptext`}
            style={{
              maxWidth: '331px',
              minWidth: '102px',
            }}
          >
            {t(label)}
          </span>
        </div>
        <div className="toolbar-button-lbl">
          <p className="labelled">{label.split(' ')[0]}</p>
          {/* <hr className="linestyle"></hr> */}
        </div>
      </div>
      {/* <h4
        style={{
          border: '0px',
          height: '1px',
          backgroundColor: '#20A5D6',
          width: '50px',
          marginTop: '50px',
          marginLeft: '-7px',
        }}
      >
        {''}
      </h4> */}
    </>
  );
}

ToolbarButton.propTypes = {
  id: PropTypes.string,
  isActive: PropTypes.bool,
  /** Display label for button */
  label: PropTypes.string.isRequired,
  /** Alternative text to show when button is active */
  labelWhenActive: PropTypes.string,
  className: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ]),
  onClick: PropTypes.func,
  /** Determines if we show expandable 'caret' symbol */
  isExpandable: PropTypes.bool,
  /** Direction of expandable 'caret' symbol */
  isExpanded: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

ToolbarButton.defaultProps = {
  isActive: false,
  className: 'toolbar-button',
};

export default withTranslation('Buttons')(ToolbarButton);
