import { SET_PANAIMAGE } from './../constants/ActionTypes.js';
const panaimage = (state = { id: '' }, action) => {
  if (action.type === SET_PANAIMAGE) {
    return { id: action.payload };
  } else {
    return state;
  }
};

export default panaimage;
