// export const ApiList = 'http://18.210.63.15:8006';
// export const ApiFileSend = 'http://18.210.63.15:9003'; //dcm file
// export const ApiPana = 'http://18.210.63.15:9001';
// export const ApiSideview = 'http://18.210.63.15:9002';
// export const Api3d = 'http://18.210.63.15:9000';
// export const ApiMetaFileGetter = 'http://18.210.63.15:9004';
// export const ApiDI = 'http://18.210.63.15:9005';

// export const Api = 'http://192.168.100.7';
// export const ApiList = 'http://192.168.100.7:8005';
// // export const ApiFileSend = 'http://192.168.100.43:9003';
// export const ApiMetaFileGetter = 'http://192.168.100.7:9004';
// export const ApiPana = 'http://192.168.100.226:8001';
// export const ApiSideview = 'http://192.168.100.226:9002';
// export const Api3d = 'http://192.168.100.226:9000';
// export const ApiDI = 'http://192.168.100.43:9005';

//live Setup   http://144.24.211.102/
// export const Api = 'http://192.168.100.11';
// export const Base = 'http://192.168.100.48:3000';
// export const ApiList = 'http://192.168.100.48:3001';
// export const ApiFileSend = 'http://192.168.100.48:8333'; //dcm file
// export const ApiPana = 'http://192.168.100.48:8001';
// export const ApiSideview = 'http://192.168.100.48:8002';
// export const Api3d = 'http://192.168.100.48:9000';
// export const ApiMetaFileGetter = 'http://192.168.100.48:9004';
// export const ApiDI = 'http://192.168.100.48:9005';a
//LIVE IP 59.103.158.165

export const Api = 'https://dentistviewer-api-test.icenna.com';
export const Base = 'https://dentistviewer-api-test.icenna.com';
export const ApiList = 'https://dentistviewer-api-test.icenna.com';
export const ApiFileSend = 'https://dentistviewer-api-test.icenna.com'; //dcm file
export const ApiPana = 'https://dentistviewer-api-test.icenna.com';
export const ApiSideview = 'https://dentistviewer-api-test.icenna.com';
export const Api3d = 'https://dentistviewer-api-test.icenna.com';
export const ApiMetaFileGetter = 'https://dentistviewer-api-test.icenna.com';
export const ApiDI = 'http://144.24.211.102:8080';

//local setup for office
/* export const Api = 'http://192.168.100.11';
export const Base = 'http://192.168.100.24:3000';
export const ApiList = 'http://192.168.100.24:9008';
export const ApiFileSend = 'http://192.168.100.24:8003'; //dcm file
export const ApiPana = 'http://192.168.100.24:8001';
export const ApiSideview = 'http://192.168.100.24:9002';
export const Api3d = 'http://192.168.100.24:9000';
export const ApiMetaFileGetter = 'http://192.168.100.24:9004';
export const ApiDI = 'http://192.168.100.24:9005'; */

/*
/*


export const ApiList = 'https://7cvm389j-8000.inc1.devtunnels.ms';
export const ApiFileSend = 'https://7cvm389j-8003.inc1.devtunnels.ms'; //dcm file
export const ApiPana = 'https://7cvm389j-8001.inc1.devtunnels.ms';
export const ApiSideview = 'https://7cvm389j-8002.inc1.devtunnels.ms';
export const Api3d = 'https://7cvm389j-9000.inc1.devtunnels.ms';
export const ApiMetaFileGetter = 'https://7cvm389j-9004.inc1.devtunnels.ms'; */
