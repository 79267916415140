import log from '../log.js';
import { retrieveStudyMetadata } from './retrieveStudyMetadata';
import { ApiList } from '../../../viewer/src/components/BaseUrl/baseUrl.js';

const fetchStudy = async (ApiList, studyInstanceUIDs) => {
  try {
    const response = await fetch(`${ApiList}/api/v1/local`);
    const result = await response.json();

    const matchedEntry = result.data.find(
      x => x.suid === studyInstanceUIDs[0] // Assuming first study UID
    );

    if (matchedEntry) {
      localStorage.setItem('folderpath', matchedEntry.folderPath);
      localStorage.setItem('patientName', matchedEntry.patientName);
      return matchedEntry.folderPath;
    }
    return null;
  } catch (error) {
    console.error('Error fetching study:', error);
    return null;
  }
};

export default async function retrieveStudiesMetadata(
  server,
  studyInstanceUIDs,
  filters,
  separateSeriesInstanceUIDFilters = false
) {
  // First, fetch the folder path
  await fetchStudy(ApiList, studyInstanceUIDs);

  // Create an empty array to store the Promises for each metaData retrieval call
  const promises = [];

  // Loop through the array of studyInstanceUIDs
  for (const StudyInstanceUID of studyInstanceUIDs) {
    console.log('runs', StudyInstanceUID);

    // Verify folderpath is set
    const folderpath = localStorage.getItem('folderpath');
    console.log('Folderpath:', folderpath);

    // Send the call and resolve or reject the related promise based on its outcome
    const promise = retrieveStudyMetadata(
      server,
      StudyInstanceUID,
      folderpath,
      filters,
      separateSeriesInstanceUIDFilters
    );

    // Add the current promise to the array of promises
    promises.push(promise);
  }

  // When all of the promises are complete, this callback runs
  try {
    const result = await Promise.all(promises);
    return result;
  } catch (error) {
    log.warn(error);
    throw error;
  }
}
