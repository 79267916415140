import { SET_PANAINDEX } from './../constants/ActionTypes.js';
const panaindex = (state = { id: '' }, action) => {
  if (action.type === SET_PANAINDEX) {
    return { id: action.payload };
  } else {
    return state;
  }
};

export default panaindex;
