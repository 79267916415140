import { SET_INDEX } from '../constants/ActionTypes.js';
const currentImageIndex = (state = { id: '' }, action) => {
  if (action.type === SET_INDEX) {
    return { id: action.payload };
  } else {
    return state;
  }
};

export default currentImageIndex;
