import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  createRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './ViewportDownloadForm.css';
import './ViewportDownloadForm.styl';
import { TextInput, Select, Icon } from '@dcmcloud/ui';
import classnames from 'classnames';
import ReactToPdf from 'react-to-pdf';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { ApiList } from '../../../../../viewer/src/components/BaseUrl/baseUrl';

const FILE_TYPE_OPTIONS = [
  {
    key: 'jpg',
    value: 'jpg',
  },
  {
    key: 'png',
    value: 'png',
  },
  {
    key: 'pdf',
    value: 'pdf',
  },
  {
    key: 'pdf-zip',
    value: 'zip (pdf)',
  },
];

const DEFAULT_FILENAME = 'image';
const REFRESH_VIEWPORT_TIMEOUT = 1000;

function getKey() {
  var urlArray = window.location.href.split('/');
  var keyTypeArray = urlArray[urlArray.length - 1];
  var moreSplit = keyTypeArray.split('%5')[0];

  return moreSplit;
}

function loadUploadedImage() {
  document.getElementById('uploadBtnDiv').style.display = 'none';
  document.getElementById('deleteBtnDiv').style.display = 'block';
  event.currentTarget.getElementsByTagName('img')[0].src;
  document.getElementById(
    'image_Preview'
  ).src = event.currentTarget.getElementsByTagName('img')[0].src;

  document.getElementsByClassName(
    'preview-header'
  )[0].innerHTML = event.currentTarget.getElementsByTagName('div')[0].innerHTML;
  document.getElementsByClassName('preview-header')[0].style.right = '142px';
  MainFileName = event.currentTarget.getElementsByTagName('input')[0].value;
  //alert(MainFileName);
  // document.getElementById('FileID').value = MainFileName;
  // alert(document.getElementById('FileID').value);
}

function ShowLoading() {
  try {
    document.getElementById('uploadingDiv').style.display = 'flex';
  } catch {}
}

function HideLoading() {
  try {
    document.getElementById('uploadingDiv').style.display = 'none';
  } catch {}
}

var MainFileName = '';

const ViewportDownloadForm = ({
  activeViewport,
  onClose,
  updateViewportPreview,
  enableViewport,
  disableViewport,
  toggleAnnotations,
  loadImage,
  downloadBlob,
  defaultSize,
  minimumSize,
  maximumSize,
  canvasClass,
}) => {
  const [t] = useTranslation('ViewportDownloadForm');

  const [filename, setFilename] = useState(DEFAULT_FILENAME);
  const [fileType, setFileType] = useState('jpg');
  const [allFiles, setAllFiles] = useState([]);
  const [pass, setPass] = useState('');
  const [loading, setLoading] = useState(false);
  const ref = React.createRef();
  const [keyImages, setKeyImages] = useState([]);
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4, 2],
  };

  const history = useHistory();

  const [dimensions, setDimensions] = useState({
    width: defaultSize,
    height: defaultSize,
  });

  const [showAnnotations, setShowAnnotations] = useState(true);

  const [keepAspect, setKeepAspect] = useState(true);
  const [aspectMultiplier, setAspectMultiplier] = useState({
    width: 1,
    height: 1,
  });

  const [viewportElement, setViewportElement] = useState();
  const [viewportElementDimensions, setViewportElementDimensions] = useState({
    width: defaultSize,
    height: defaultSize,
  });

  const [downloadCanvas, setDownloadCanvas] = useState({
    ref: createRef(),
    width: defaultSize,
    height: defaultSize,
  });

  const [viewportPreview, setViewportPreview] = useState({
    src: null,
    width: defaultSize,
    height: defaultSize,
    clicked: false,
  });

  const [keyImagePreview, setKeyImagePreview] = useState({
    src: null,
    name: null,
    width: defaultSize,
    height: defaultSize,
  });
  console.log(keyImagePreview, 'keyImagePreview');
  const [error, setError] = useState({
    width: false,
    height: false,
    filename: false,
  });

  const hasError = Object.values(error).includes(true);

  const refreshViewport = useRef(null);

  const downloadImage = () => {
    downloadBlob(
      filename || DEFAULT_FILENAME,
      fileType,
      viewportElement,
      downloadCanvas.ref.current
    );
    console.log(viewportElement);
    var Img1 = document.getElementById('image_Preview');

    // var doc = new jsPDF();
    // doc.addImage(downloadCanvas.ref.current, 10, 10);
    // doc.save('ImgToPDF.pdf');
    const linkSource = `data:application/pdf;base64,${
      Img1.src.split('base64,')[1]
    }`;
    console.log(Img1.src.split('base64,')[1]);
    var worker = html2pdf()
      .from(Img1)
      .save();
    // const downloadLink = document.createElement('a');
    // const fileName = 'abc.pdf';
    // downloadLink.href = linkSource;
    // downloadLink.download = fileName;
    // downloadLink.click();
  };

  const callCurrentImage = event => {
    document.getElementsByClassName('current')[0].click();
  };

  const loadCurrentImage = event => {
    document.getElementById('uploadBtnDiv').style.display = 'block';
    document.getElementById('deleteBtnDiv').style.display = 'none';
    event.currentTarget.getElementsByTagName('img')[0].src;
    document.getElementById(
      'image_Preview'
    ).src = event.currentTarget.getElementsByTagName('img')[0].src;

    document.getElementsByClassName(
      'preview-header'
    )[0].innerHTML = event.currentTarget.getElementsByTagName(
      'div'
    )[0].innerHTML;
    document.getElementsByClassName('preview-header')[0].style.right = '250px';
  };

  const deleteImage = name => {
    const patientName = localStorage.getItem('v3Patient');
    setLoading(true);
    fetch(
      `${ApiList}/api/v1/keyImages/delete?keyImage=${name}&patientName=${patientName}`,
      {
        method: 'DELETE',
      }
    )
      // fetch(
      //   `http://192.168.100.53:8000/api/v1/keyImages/delete?keyImage=${name}&patientName=${patientName}`,
      //   {
      //     method: 'DELETE',
      //   }
      // )
      .then(response => {
        response.json();
        setLoading(false);
        getAllKeyImages();
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const getAllImages = async () => {
    setLoading(true);
    var keyformurl = getKey();
    fetch(
      `https://dcm-key-images.herokuapp.com/api/v1/keyImages?SUID=${keyformurl}`
    )
      .then(response => response.json())
      .then(data => {
        var abc = data.data;
        setAllFiles(abc);
        setLoading(false);
      });
  };

  // var downloadZip = async () => {
  //   var Img1 = document.getElementById('image_Preview');

  //   const bodyData = {
  //     image: [Img1.src],
  //   };
  //   await fetch('http://localhost:8000/api/v1/download', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json; charset=UTF-8',
  //     },
  //     body: JSON.stringify(bodyData),
  //   })
  //     .then(res => res.json())
  //     .then(data => {
  //       `http://localhost:8000/${data.path}`, 'data123';
  //       const link = document.createElement('a');
  //       link.href = `http://localhost:8000/${data.path}`;
  //       // link.setAttribute("download", "file.pdf");
  //       // document.body.appendChild(link);
  //       link.click();
  //     });
  // };

  var downloadZip = async () => {
    var Img1 = document.getElementById('image_Preview');

    const bodyData = {
      base64: Img1.src.split('base64,')[1],
      password: pass,
    };
    await fetch('https://encryptor-zip.vercel.app/download', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(bodyData),
    })
      .then(res => res.blob())
      .then(data => {
        console.log(data, 'data123123');
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        // tempLink.setAttribute('download', 'filename.csv');
        tempLink.click();
      });
  };

  const uploadImage = async () => {
    // ShowLoading();
    // var Img1 = document.getElementById('image_Preview');
    var Img1 = viewportPreview.src;
    const patientName = localStorage.getItem('v3Patient');

    setLoading(true);

    function urltoFile(url, filename, mimeType) {
      return fetch(url)
        .then(function(res) {
          return res.arrayBuffer();
        })
        .then(function(buf) {
          return new File([buf], filename, { type: mimeType });
        });
    }

    let newFile;
    await urltoFile(Img1, `${Date.now()}.jpg`, 'image/jpg').then(function(
      file
    ) {
      newFile = file;
    });

    console.log('finalResult', newFile);

    const formData = new FormData();

    formData.append('patientName', patientName);
    formData.append('keyImage', newFile);

    // const bodyData = {
    //   keyImage: Img1.src,
    //   patientName: patientName,
    // };

    console.log('finalResult', formData);

    var requestOptions = {
      method: 'POST',
      body: formData,
      redirect: 'follow',
    };

    fetch(`${ApiList}/api/v1/keyImages`, requestOptions)
      // fetch(`http://192.168.100.53:8000/api/v1/keyImages`, requestOptions)
      .then(response => response.text())
      .then(result => {
        getAllKeyImages();
        setLoading(false);
        console.log(result);
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
  };

  /**
   * @param {object} event - Input change event
   * @param {string} dimension - "height" | "width"
   */
  const onDimensionsChange = (event, dimension) => {
    const oppositeDimension = dimension === 'height' ? 'width' : 'height';
    const sanitizedTargetValue = event.target.value.replace(/\D/, '');
    const isEmpty = sanitizedTargetValue === '';
    const newDimensions = { ...dimensions };
    const updatedDimension = isEmpty
      ? ''
      : Math.min(sanitizedTargetValue, maximumSize);

    if (updatedDimension === dimensions[dimension]) {
      return;
    }

    newDimensions[dimension] = updatedDimension;

    if (keepAspect && newDimensions[oppositeDimension] !== '') {
      newDimensions[oppositeDimension] = Math.round(
        newDimensions[dimension] * aspectMultiplier[oppositeDimension]
      );
    }

    // In current code, keepAspect is always `true`
    // And we always start w/ a square width/height
    setDimensions(newDimensions);

    // Only update if value is non-empty
    if (!isEmpty) {
      setViewportElementDimensions(newDimensions);
      setDownloadCanvas(state => ({
        ...state,
        ...newDimensions,
      }));
    }
  };

  const error_messages = {
    width: t('minWidthError'),
    height: t('minHeightError'),
    filename: t('emptyFilenameError'),
  };

  const renderErrorHandler = errorType => {
    if (!error[errorType]) {
      return null;
    }

    return <div className="input-error">{error_messages[errorType]}</div>;
  };

  const onKeepAspectToggle = () => {
    const { width, height } = dimensions;
    const aspectMultiplier = { ...aspectMultiplier };
    if (!keepAspect) {
      const base = Math.min(width, height);
      aspectMultiplier.width = width / base;
      aspectMultiplier.height = height / base;
      setAspectMultiplier(aspectMultiplier);
    }

    setKeepAspect(!keepAspect);
  };

  const validSize = value => (value >= minimumSize ? value : minimumSize);
  const loadAndUpdateViewports = useCallback(async () => {
    const { width: scaledWidth, height: scaledHeight } = await loadImage(
      activeViewport,
      viewportElement,
      dimensions.width,
      dimensions.height
    );

    // toggleAnnotations(showAnnotations, viewportElement);

    const scaledDimensions = {
      height: validSize(scaledHeight),
      width: validSize(scaledWidth),
    };

    setViewportElementDimensions(scaledDimensions);
    setDownloadCanvas(state => ({
      ...state,
      ...scaledDimensions,
    }));

    const {
      dataUrl,
      width: viewportElementWidth,
      height: viewportElementHeight,
    } = await updateViewportPreview(
      viewportElement,
      downloadCanvas.ref.current,
      fileType
    );

    if (!viewportPreview.clicked) {
      setViewportPreview(state => ({
        ...state,
        src: dataUrl,
        width: validSize(viewportElementWidth),
        height: validSize(viewportElementHeight),
      }));
    }
  }, [
    loadImage,
    activeViewport,
    viewportElement,
    dimensions.width,
    dimensions.height,
    validSize,
    updateViewportPreview,
    downloadCanvas.ref,
    fileType,
  ]);

  useEffect(() => {
    enableViewport(viewportElement);

    return () => {
      disableViewport(viewportElement);
    };
  }, [disableViewport, enableViewport, viewportElement]);

  useEffect(() => {
    if (refreshViewport.current !== null) {
      clearTimeout(refreshViewport.current);
    }

    refreshViewport.current = setTimeout(() => {
      refreshViewport.current = null;
      loadAndUpdateViewports();
    }, REFRESH_VIEWPORT_TIMEOUT);
  }, [
    activeViewport,
    viewportElement,
    showAnnotations,
    dimensions,
    loadImage,
    toggleAnnotations,
    updateViewportPreview,
    fileType,
    downloadCanvas.ref,
    minimumSize,
    maximumSize,
    loadAndUpdateViewports,
  ]);

  useEffect(() => {
    const { width, height } = dimensions;
    const hasError = {
      width: width < minimumSize,
      height: height < minimumSize,
      filename: !filename,
    };

    setError({ ...hasError });
  }, [dimensions, filename, minimumSize]);
  localStorage.setItem('viewportPreview', viewportPreview);
  console.log('viewportPreview', viewportPreview);

  // useEffect(() => {
  //   setLoading(true);
  //   var keyformurl = getKey();
  //   fetch(
  //     `https://dcm-key-images.herokuapp.com/api/v1/keyImages?SUID=${keyformurl}`
  //   )
  //     .then(response => response.json())
  //     .then(data => {
  //       var abc = data.data;
  //       setAllFiles(abc);
  //       setLoading(false);
  //     });
  // }, []);

  const getAllKeyImages = async () => {
    const patientName = localStorage.getItem('v3Patient');
    setLoading(true);
    fetch(`${ApiList}/api/v1/keyImages?patientName=${patientName}`)
      // fetch(
      //   `http://192.168.100.53:8000/api/v1/keyImages?patientName=${patientName}`
      // )
      .then(response => response.json())
      .then(data => {
        var abc = data.data;
        setKeyImages(abc);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllKeyImages();
  }, []);

  return (
    <div className="ViewportDownloadForm ViewportDownloadFormMain">
      <div style={{ display: 'flex', flexDirection: 'row', height: '70%' }}>
        <div
          className="ViewportDownloadFormListDiv"
          style={{
            width: '40%',
          }}
        >
          {/* ************* CURRENT IMAGE ************* */}
          {/* {!loading && viewportPreview.src ? (
            <>
              <div
                onClick={() =>
                  setKeyImagePreview(state => ({
                    ...state,
                    src: null,
                    name: null,
                  }))
                }
                style={{
                  padding: '0 15px 15px 15px',
                  borderRadius: '10px',
                  border: '1px solid black',
                  marginBottom: '20px',
                  lineHeight: '0px',
                  backgroundColor: '#d2d2d2',
                }}
              >
                <p
                  style={{
                    // position: 'absolute',
                    bottom: '10px',
                    left: '40%',
                    right: '50%',
                    color: 'black',
                    textAlign: 'center',
                  }}
                >
                  CURRENT
                </p>
                <div
                  style={{
                    padding: '5px',
                    borderRadius: '10px',
                    border: '1px solid black',
                    marginBottom: '10px',
                    lineHeight: '0px',
                    backgroundColor: 'black',
                    position: 'relative',
                  }}
                >
                  <img
                    className="ViewportDownloadFormListDivImages"
                    src={viewportPreview.src}
                    alt={`item.key`}
                    height="100px"
                    width="140px"
                  />
                </div>
              </div>
            </>
          ) : (
            ''
          )} */}

          {/* ************* KEY IMAGES ************* */}
          <div
            // style={{ width: 500, height: 500, background: 'blue' }}
            ref={ref}
          />

          {!loading && viewportPreview.src ? (
            keyImages.length > 0 ? (
              <div
                style={{
                  padding: '0 8px',
                  borderRadius: '10px',
                  border: '1px solid black',
                  // backgroundColor: '#d2d2d2',
                  // backgroundColor: '#ffffff',
                  position: 'relative',
                }}
              >
                <p
                  style={{
                    color: 'white',
                    textAlign: 'center',
                    marginTop: '0.5rem',
                  }}
                >
                  KEY IMAGES
                </p>
                {keyImages.map(item => (
                  <div
                    style={{
                      padding: '3px',
                      borderRadius: '10px',
                      border: '1px solid black',
                      marginBottom: '10px',
                      lineHeight: '0px',
                      backgroundColor: 'black',
                      position: 'relative',
                    }}
                  >
                    <span
                      style={{
                        position: 'absolute',
                        top: '-12px',
                        right: '-12px',
                      }}
                      onClick={() => deleteImage(`${item.name}`)}
                    >
                      <Icon
                        name="dcm-bin"
                        style={{
                          height: '50px',
                          width: '50px',
                          cursor: 'pointer',
                        }}
                      />
                    </span>
                    {item.name === keyImagePreview.name && (
                      <span
                        style={{
                          position: 'absolute',
                          top: '-5px',
                          left: '15px',
                        }}
                      >
                        <Icon
                          name="check"
                          style={{
                            height: '40px',
                            width: '40px',
                            cursor: 'pointer',
                          }}
                        />
                      </span>
                    )}
                    <img
                      src={`data:image/jpg;base64, ${item.image}`}
                      alt={'Image'}
                      key={'Image'}
                      className="ViewportDownloadFormListDivImages"
                      onClick={() => {
                        setKeyImagePreview(state => ({
                          ...state,
                          src: `data:image/jpg;base64, ${item.image}`,
                          name: item.name,
                        }));
                        // setViewportPreview(state => ({
                        //   ...state,
                        //   src: `data:image/jpg;base64, ${item}`,
                        //   clicked: true,
                        // }));
                        // setViewportPreview(state => ({
                        //   ...state,
                        //   src: `data:image/jpg;base64, ${item}`,
                        //   clicked: true,
                        // }));
                      }}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <h4 style={{ color: '#d2d2d2' }}>No Key Images Found...</h4>
            )
          ) : (
            <div className="loading-image">
              <Icon name="circle-notch" className="icon-spin" />
              {t('loadingPreview')}
            </div>
          )}
        </div>

        {/* ************* RIGHT SIDE ************* */}
        {viewportPreview.src ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
            ref={ref}
          >
            <img
              id="image_Preview"
              className="viewport-preview"
              src={
                keyImagePreview.src ? keyImagePreview.src : viewportPreview.src
              }
              alt={t('imagePreview')}
              data-cy="image-preview"
              // data-cy="viewport-preview-img"
              height={
                window.innerWidth < 600
                  ? dimensions.height / 3
                  : dimensions.height / 1.4
              }
              width={
                window.innerWidth < 600
                  ? dimensions.width / 3
                  : dimensions.width
              }
            />
          </div>
        ) : (
          <div
            className="loading-image"
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon name="circle-notch" className="icon-spin" />
            {t('loadingPreview')}
          </div>
        )}
      </div>

      <div className="title">{t('formTitle')}</div>

      <div
        className="file-info-container"
        data-cy="file-info-container"
        style={{ paddingTop: '10px' }}
      >
        <div className="div-wraper">
          <div className="dimension-wrapper">
            <div className="dimensions">
              <div className="width">
                <TextInput
                  type="number"
                  min={minimumSize}
                  max={maximumSize}
                  value={dimensions.width}
                  label={t('imageWidth')}
                  onChange={evt => onDimensionsChange(evt, 'width')}
                  data-cy="image-width"
                />
                {renderErrorHandler('width')}
              </div>
              <div className="height">
                <TextInput
                  type="number"
                  min={minimumSize}
                  max={maximumSize}
                  value={dimensions.height}
                  label={t('imageHeight')}
                  onChange={evt => onDimensionsChange(evt, 'height')}
                  data-cy="image-height"
                />
                {renderErrorHandler('height')}
              </div>
            </div>
            <div className="keep-aspect-wrapper">
              <Button
                id="keep-aspect"
                className={classnames(
                  'form-button btn',
                  keepAspect ? 'active' : ''
                )}
                data-cy="keep-aspect"
                alt={t('keepAspectRatio')}
                onClick={onKeepAspectToggle}
              >
                <Icon
                  className="keep-aspect-svg"
                  name={keepAspect ? 'linkImages' : 'unlink'}
                  alt={keepAspect ? 'Dismiss Aspect' : 'Keep Aspect'}
                  height="20px"
                  width="20px"
                />
              </Button>
            </div>
          </div>

          <div className="col widthAdjustOnMobile">
            <div className="file-name">
              <TextInput
                type="text"
                data-cy="file-name"
                value={filename}
                onChange={event => setFilename(event.target.value)}
                label={t('filename')}
                id="file-name"
              />
              {renderErrorHandler('filename')}
            </div>
            <div className="file-type">
              <Select
                value={fileType}
                data-cy="file-type"
                onChange={event => setFileType(event.target.value)}
                options={FILE_TYPE_OPTIONS}
                label={t('fileType')}
              />
            </div>
          </div>
        </div>

        <div className="col mycol">
          <div className="show-annotations">
            <label htmlFor="show-annotations" className="form-check-label">
              <input
                id="show-annotations"
                data-cy="show-annotations"
                type="checkbox"
                className="form-check-input"
                checked={showAnnotations}
                onChange={event => setShowAnnotations(event.target.checked)}
                onClick={callCurrentImage}
              />
              {t('showAnnotations')}
            </label>
          </div>

          {fileType === 'zip (pdf)' ? (
            <div className="my-file-name">
              {/*
        <TextInput
            type="Password"
            value={pass}
            onChange={event => setPass(event.target.value)}
            label={'Password'}
          /> */}

              <label
                className="my-file-name-label"
                style={{ color: 'black', padding: '0px 20px' }}
              >
                Password
              </label>
              <input
                type="password"
                style={{
                  // width: '17%',
                  background: '#f3f3f3',
                  borderRadius: '4px',
                  height: '30px',
                  border: 'none',
                }}
                value={pass}
                onChange={e => setPass(e.target.value)}
              ></input>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      <div
        style={{
          height: viewportElementDimensions.height,
          width: viewportElementDimensions.width,
          position: 'absolute',
          left: '9999px',
        }}
        ref={ref => setViewportElement(ref)}
      >
        <canvas
          className={canvasClass}
          style={{
            height: downloadCanvas.height,
            width: downloadCanvas.width,
            display: 'block',
          }}
          width={downloadCanvas.width}
          height={downloadCanvas.height}
          ref={downloadCanvas.ref}
        ></canvas>
      </div>

      <div className="actions" style={{ position: 'relative' }}>
        {/* <button
          onClick={() => {
            history.push(`/keyImages/${localStorage.getItem('v3Patient')}`);
            onClose();
          }}
          style={{ cursor: 'pointer', borderRadius: '10px' }}
        >
          Key Images
        </button> */}
        <div className="mydiv">
          <div className="btn-wrapper">
            <div className="action-cancel">
              <Button
                style={{
                  fontSize: '10px',
                  background: '#d2cfcf',
                  marginRight: '10px',
                }}
                type="button"
                data-cy="cancel-btn"
                // className="btn btn-danger"
                onClick={onClose}
              >
                {t('Buttons:Cancel')}
              </Button>
            </div>
            <div className="action-save">
              {/* <button
            disabled={hasError}
            onClick={downloadImage}
            className="btn btn-primary"
            data-cy="download-btn"
          >
            {t('Buttons:Download')}
          </button> */}
              <ReactToPdf
                targetRef={ref}
                filename="div-blue.pdf"
                // options={options}
                // x={0.5}
                // y={0.5}
                // scale={0.8}
              >
                {({ toPdf }) => (
                  <Button
                    style={{
                      fontSize: '10px',
                      background: `linear-gradient(to bottom, #0f8afb 0%, #3c40ef 100%)`,
                      color: 'white',
                    }}
                    // className="btn btn-primary"
                    onClick={
                      fileType === 'pdf'
                        ? toPdf
                        : fileType === 'zip (pdf)'
                        ? downloadZip
                        : downloadImage
                    }
                    // onClick={() => window.print()}
                  >
                    {t('Buttons:Download')}
                  </Button>
                )}
              </ReactToPdf>
            </div>
          </div>

          <div
            id="uploadBtnDiv"
            className="action-save"
            style={{ marginTop: '-126px' }}
          >
            <button
              disabled={hasError}
              onClick={uploadImage}
              className="btn btn-primary"
              data-cy="upload-btn"
            >
              {t('Buttons:Save')}
            </button>
          </div>

          {/* <div
            id="deleteBtnDiv"
            style={{ display: 'none' }}
            className="action-save"
          >
            <button
              disabled={hasError}
              onClick={deleteImage}
              className="btn btn-danger"
              data-cy="delete-btn"
            >
              {t('Buttons:Delete')}
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

ViewportDownloadForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  activeViewport: PropTypes.object,
  updateViewportPreview: PropTypes.func.isRequired,
  enableViewport: PropTypes.func.isRequired,
  disableViewport: PropTypes.func.isRequired,
  toggleAnnotations: PropTypes.func.isRequired,
  loadImage: PropTypes.func.isRequired,
  downloadBlob: PropTypes.func.isRequired,
  /** A default width & height, between the minimum and maximum size */
  defaultSize: PropTypes.number.isRequired,
  minimumSize: PropTypes.number.isRequired,
  maximumSize: PropTypes.number.isRequired,
  canvasClass: PropTypes.string.isRequired,
};

export default ViewportDownloadForm;
