import React, { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getActiveContexts } from '../store/layout/selectors.js';

let AppContext = React.createContext({});

export const CONTEXTS = {
  CORNERSTONE: 'ACTIVE_VIEWPORT::CORNERSTONE',
  VTK: 'ACTIVE_VIEWPORT::VTK',
};

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children, config }) => {
  const activeViewerRef = useRef();
  const activeContexts = useSelector(state => getActiveContexts(state));
  const [activeViewersIndex, setActiveViewersIndex] = useState([]);
  const [openLayoutDrawer, setOpenLayoutDrawer] = useState(false);
  const [activediv, setActivediv] = useState(null);
  const [measurement, setMeasurement] = useState(false);
  const [tapopen, setTabopen] = useState(false);
  const [modal, setmodal] = useState(false);
  const [modalSideView, setmodalSideView] = useState(false);
  const [modalAxialView, setModalAxialView] = useState(false);
  const [modal3d, setModal3d] = useState(false);
  const [viewerLength, setViewerLength] = useState(0);
  const [mainViewerDiv, setMainViewerDiv] = useState(null);
  const [resetCurve, setResetCurve] = useState(false);
  const [currentSideImg, setCurrentSideImg] = useState('');

  const [hideCurve, setHideCurve] = useState(false);

  const [zoomPercentage, setzoomPercentage] = useState(0);
  const [sideViewIndex2, setsideViewIndex2] = useState(0);
  const [undoBtnClick, setUndoBtnClick] = useState(false);
  const [redoBtnClick, setRedoBtnClick] = useState(false);

  // const [container3d, setContainer3d] = useRef(null);

  const [viewerNumber, setViewerNumber] = useState(0);
  const [pana, setPana] = useState(false);
  const [selected, setSelected] = useState(0);
  const [curentLayoutViewDrawer, setCurentLayoutViewDrawer] = useState('');
  const [sideImgNo, setSideImgNo] = useState(0);
  const [panaPointX, setPanaPointX] = useState(0);
  const [panaPointY, setPanaPointY] = useState(0);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [controlPointsArr, setControlPointsArr] = useState([]);
  const [panaromicImg, setPanaromicImg] = useState('');
  const [curveImg, setCurveImg] = useState('');
  const [numbersArray, setNumbersArray] = useState('');
  const [isDraggingSideViewLine, setisDraggingSideViewLine] = useState(false);
  const [dragOffsetY, setDragOffsetY] = useState(0);
  const [drawDotstate, setdrawDotstate] = useState(false);
  const [selectedButton, setSelectedButton] = useState('x');
  const [isDraggingTop, setIsDraggingTop] = useState(false);
  const [isDraggingBottom, setIsDraggingBottom] = useState(false);
  const [panoLoading, setPanoLoading] = useState(false);
  const [initialArray, setInitialArray] = useState('');
  const canvasRef = useRef(null);
  const ZoomRef = useRef(null);
  const [isArea, setIsArea] = useState(false);

  const containerRef = useRef(null);

  const updateContainerRef = ref => {
    containerRef.current = ref;
  };

  const [curveDraw, setCurveDraw] = useState(false);
  const [is2d, setIs2d] = useState(false);
  const [resetBtn, setResetBtn] = useState(false);
  const [state, setState] = useState([]);
  const [action, setAction] = useState(null);

  const addState = newState => {
    setState(prev => [...prev, newState]);
  };

  const undo = () => {
    setAction('undo');
    // Logic for undo
  };

  const redo = () => {
    setAction('redo');
    // Logic for redo
  };

  const [saveModal, setsaveModal] = useState(false);
  const [AxialSwap, setAxialSwap] = useState(false);
  const [panoSwap, setpanoSwap] = useState(false);
  const [sideSwap, setsideSwap] = useState(false);
  const [compareModal, setcompareModal] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [EmailModal, setEmailModal] = useState(false);
  const [fileName, setFileName] = useState([]);
  const [controlPointsData, setControlPointsData] = useState([]);
  const [savevalue, setSaveValue] = useState('');
  const [uniqueName, setUniqueName] = useState([]);
  const [studyListDate, setstudyListDate] = useState([]);
  const [StudyList, setStudyList] = useState([]);
  const [leftPosition, setLeftPosition] = useState('416px');


  const [key, setKey] = useState('');

  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <AppContext.Provider
      value={{
        appConfig: config,
        activeContexts,
        panoLoading,
        isArea,
        setIsArea,
        setPanoLoading,
        measurement,
        setMeasurement,
        openLayoutDrawer,
        panaromicImg,
        numbersArray,
        hideCurve,
        setHideCurve,
        setNumbersArray,
        setPanaromicImg,
        setOpenLayoutDrawer,
        curentLayoutViewDrawer,
        initialArray,
        setInitialArray,
        mainViewerDiv,
        setMainViewerDiv,
        containerRef,
        currentSideImg,
        setCurrentSideImg,
        updateContainerRef,
        resetCurve,
        setResetCurve,
        state,
        action,
        addState,
        undo,
        redo,
        setCurentLayoutViewDrawer,
        viewerLength,
        setViewerLength,
        activediv,
        curveImg,
        setCurveImg,
        is2d,
        setIs2d,
        setActivediv,
        isSaveButtonDisabled,
        setIsSaveButtonDisabled,
        modal,
        setModalAxialView,
        modalAxialView,
        setmodalSideView,
        modalSideView,
        modal3d,
        setModal3d,
        setmodal,
        setViewerNumber,
        viewerNumber,
        tapopen,
        setTabopen,
        selected,
        setSelected,
        resetBtn,
        setResetBtn,
        pana,
        setPana,
        sideImgNo,
        setSideImgNo,
        activeViewerRef,
        activeViewersIndex,
        setActiveViewersIndex,
        panaPointX,
        setPanaPointX,
        panaPointY,
        setPanaPointY,
        controlPointsArr,
        setControlPointsArr,
        curveDraw,
        setCurveDraw,
        isDraggingSideViewLine,
        setisDraggingSideViewLine,
        setDragOffsetY,
        dragOffsetY,
        setdrawDotstate,
        drawDotstate,
        setSelectedButton,
        selectedButton,
        isDraggingTop,
        setIsDraggingTop,
        isDraggingBottom,
        setIsDraggingBottom,
        setzoomPercentage,
        zoomPercentage,
        sideViewIndex2,
        setsideViewIndex2,
        undoBtnClick,
        setUndoBtnClick,
        redoBtnClick,
        setRedoBtnClick,
        canvasRef,
        ZoomRef,
        saveModal,
        setsaveModal,
        openModal,
        setOpenModal,
        isDrawing,
        setIsDrawing,
        fileName,
        setFileName,
        savevalue,
        setSaveValue,
        controlPointsData,
        setControlPointsData,
        selectedItem,
        setSelectedItem,
        uniqueName,
        setUniqueName,
        key,
        setKey,
        studyListDate,
        setstudyListDate,
        AxialSwap,
        setAxialSwap,
        leftPosition,
        setLeftPosition,
        panoSwap,
        setpanoSwap,
        sideSwap,
        setsideSwap,
        StudyList,
        setStudyList,
        compareModal,
        setcompareModal,
        EmailModal,
        setEmailModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const withAppContext = Component => {
  return function WrappedComponent(props) {
    const {
      appConfig,
      activeContexts,
      curveDraw,
      resetBtn,
      setResetBtn,
      setCurveDraw,
      is2d,
      setIs2d,
      numbersArray,
      setNumbersArray,
    } = useAppContext();
    return (
      <Component
        {...props}
        appConfig={appConfig}
        activeContexts={activeContexts}
        curveDraw={curveDraw}
        resetBtn={resetBtn}
        setIs2d={setIs2d}
        is2d={is2d}
        setResetBtn={setResetBtn}
        setCurveDraw={setCurveDraw}
      />
    );
  };
};

export default AppContext;
