import { SET_PANAINDEX2 } from './../constants/ActionTypes.js';
const panaindex2 = (state = { id: '' }, action) => {
  if (action.type === SET_PANAINDEX2) {
    return { id: action.payload };
  } else {
    return state;
  }
};

export default panaindex2;
