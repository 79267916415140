import extensions from './extensions';
import loading from './loading';
import preferences from './preferences';
import servers from './servers';
import studies from './studies';
import timepointManager from './timepointManager';
import viewports from './viewports';
import currentImageIndex from './currentImageIndex';
import panaindex from './PanaIndex';
import panaindex2 from './PanaIndex2';
import panaimage from './PanaImage';
// import printing from './print';

const reducers = {
  extensions,
  loading,
  preferences,
  servers,
  studies,
  currentImageIndex,
  timepointManager,
  viewports,
  panaindex,
  panaindex2,
  panaimage,
  // printing,
};

export default reducers;
